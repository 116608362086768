// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('active');
        }
        //else {
        //$element.removeClass('active');
        //}
    });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// IMAGE REPLACE - img src to bg css
function replaceSrc() {

    $('.js-replace-img').each(function() {

        var src = $(this).find("img").attr("src");
        $(this).css("background-image", "url(" + src + ")");
    });
}

jQuery(function() {
    if ($(".js-replace-img")) {
        replaceSrc();
    }
});

// Responsive menu
jQuery('#btnMobile').click(function() {
  jQuery(this).toggleClass('cross');
  jQuery('.main-nav-wrapper').toggleClass('open');
  jQuery(document.body).toggleClass('has-overlay');
});

// Hide/Show box search
jQuery('.search-btn').click(function() {
  jQuery(this).toggleClass('cross');
  jQuery('#overlay').toggleClass('active');
  jQuery(document.body).toggleClass('has-overlay');
});

jQuery(function() {
  jQuery('#image-gallery').lightSlider({
      gallery:true,
      item:1,
      thumbItem:5,
      slideMargin: 0,
      speed:500,
      auto:false,
      loop:true,
      pager:true,
      controls:true,
      currentPagerPosition:'middle',
      onSliderLoad: function() {
          jQuery('#image-gallery').removeClass('cS-hidden');
      }
  });
});

// Menu click Open/close
$('#menu-primary-navigation > li.menu-item-has-children').click(function(event) {
  $('#menu-primary-navigation > li > .sub-menu').removeClass('active');
  if(!($(this).hasClass('active'))){
    $('#menu-primary-navigation > li').removeClass('active');
    $(".sub-menu", this).toggleClass('active');
    $(this).addClass('active');
    }else{
      $(this).toggleClass('active');
  }
});

$('#menu-primary-navigation > li.menu-item-has-children > a').click(function(event) {
  event.preventDefault();
});
